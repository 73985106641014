import React from 'react';
import { Trans,useTranslation  } from 'react-i18next';
import { Icon } from '@cib-jpm-ui-toolkit/icon';
import './accordian.css';

function Panel_1(props){
  const { t, i18n } = useTranslation();
  return (
    <div className='infoText'>
      <p dangerouslySetInnerHTML={{__html:t("page.makecomplaint.text")}}/>
      <div className="complaint-contact">
        <p dangerouslySetInnerHTML={{__html: t("page.makecomplaint.complaintcontact.first.heading")}}/>
        <div className='mail-icon-container'><Icon accessibleText="Email" data-jpmui-test="icon-access-text-example"
                                                   name="message" size={15}/></div>
        <a href="mailTo:kid.complaints@jpmorgan.com"> <span
          dangerouslySetInnerHTML={{__html: t("page.makecomplaint.emailtext")}}/></a>
        <ul className="complain-content-list">
          <li>
            <span dangerouslySetInnerHTML={{__html: t("page.makecomplaint.uk")}}/>
            <span dangerouslySetInnerHTML={{__html: t("page.makecomplaint.phone")}}/>
          </li>
          <li>
            <span dangerouslySetInnerHTML={{__html: t("page.makecomplaint.international")}}/>
            <span dangerouslySetInnerHTML={{__html: t("page.makecomplaint.phoneint")}}/>
          </li>
        </ul>
        <address>
          <span dangerouslySetInnerHTML={{__html: t("page.makecomplaint.address.lbl")}}/>
          <span dangerouslySetInnerHTML={{__html: t("page.makecomplaint.address")}}/>
        </address>

        <p dangerouslySetInnerHTML={{__html: t("page.makecomplaint.complaintcontact.second.heading")}}/>
        <div className='mail-icon-container'><Icon accessibleText="Email" data-jpmui-test="icon-access-text-example"
                                                   name="message" size={15}/></div>
        <a href="mailTo:kid.complaints@jpmorgan.com"> <span
          dangerouslySetInnerHTML={{__html: t("page.makecomplaint.emailtext")}}/></a>
        <ul className="complain-content-list">
          <li>
            <span dangerouslySetInnerHTML={{__html: t("page.makecomplaint.germany")}}/>
            <span dangerouslySetInnerHTML={{__html: t("page.makecomplaint.germany.phone")}}/>
          </li>
          <li>
            <span dangerouslySetInnerHTML={{__html: t("page.makecomplaint.international")}}/>
            <span dangerouslySetInnerHTML={{__html: t("page.makecomplaint.second.phoneint")}}/>
          </li>
        </ul>
        { /*only for EN and Germany block*/}
        <span dangerouslySetInnerHTML={{__html: t("page.makecomplaint.third.or")}}/>
        {t("page.makecomplaint.third.emailtext").length > 0 ?  <div className='mail-icon-container'><Icon accessibleText="Email" data-jpmui-test="icon-access-text-example"
                                                    name="message" size={15}/></div> : null}
        <a href="mailTo:kontakt.zertifikate@jpmorgan.com"><span
          dangerouslySetInnerHTML={{__html: t("page.makecomplaint.third.emailtext")}}/></a>

        <ul className="complain-content-list">
          <li>
            <span dangerouslySetInnerHTML={{__html: t("page.makecomplaint.third.germany")}}/>
            <span dangerouslySetInnerHTML={{__html: t("page.makecomplaint.third.germany.phone")}}/>
          </li>
          <li>
            <span dangerouslySetInnerHTML={{__html: t("page.makecomplaint.third.international")}}/>
            <span dangerouslySetInnerHTML={{__html: t("page.makecomplaint.third.phoneint")}}/>
          </li>
        </ul>
        <span dangerouslySetInnerHTML={{__html: t("page.makecomplaint.third.applicable-text")}}/>
        { /* end of only for EN and Germany block*/}
        <address>
          <span dangerouslySetInnerHTML={{__html: t("page.makecomplaint.address.lbl")}}/>
          <span dangerouslySetInnerHTML={{__html: t("page.makecomplaint.second.address")}}/>
        </address>
      </div>
      <p dangerouslySetInnerHTML={{__html: t("page.makecomplaint.lasttext")}}/>

    </div>
  )
}

export default Panel_1;
